/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux';
import authReducer, { userInfoReducer } from 'common/authReducers';
import { toasts } from 'common/toasterReducers';
import patientInfo, {
  updatePatientInfoReducer,
  resendInviteReducer,
  notMovingForwardReducer,
  setPhaseReducer,
  patientDetailsReducer,
  needsAttentionReducer,
  exitPatientFromStudyReducer,
} from 'containers/PatientDetails/reducer';
import appointments from 'containers/Appointments/reducer';
import patientCardReducer from 'common/patientCardReducer';
import shareOutcomesReportReducer from 'common/shareOutcomesReportReducer';
import {
  verifyOTPReducer,
  ModalCloseOnPromiseReducer,
  getProcedureLocationsReducer,
  getProcedureProvidersReducer,
  addNewProcedureReducer,
  saveSearchFiltersReducer,
  getProcedureMasterProvidersReducer,
  getDepartmentOnlyProvidersReducer,
  deleteCustomFiltersReducer,
  getDepartmentProceduresReducer,
  getDepartmentProvidersReducer,
  getCounterSigneReducer,
  AddPatientReducer,
  documentsReducer,
  LoadingReducer,
  sendVerificationCodeReducer,
  requestsAndCallBackReducer,
  cohortsReducer,
} from 'common/reducer';
import communicationLog, {
  patientNotesReducer,
} from 'containers/Notes/reducer';

import { procedureManagersReducer } from 'components/TeamInformation/reducer';

import { notificationsReducer } from 'containers/NavNotificationMenu/reducer';

import { patientSearchReducer } from 'containers/PatientSearch/reducer';

import { navBarSelectedPerformerReducer } from 'containers/NavigationBar/reducer';
import {
  patientDetailsReducer as patientInfoReducer,
  addNewPatientReducer,
} from 'containers/Dashboard/reducer';
import consentReducer, {
  consentResendInviteReducer,
  downloadSignedConsentReducer,
} from 'containers/Consent/reducer';
import surveys from 'containers/Surveys/reducer';
import conversations from 'containers/Conversations/reducer';
import messages from 'containers/Conversations/Components/MessagesList/reducer';
import patientNoteDetailsReducer from 'containers/Notes/Components/NoteDetails/reducer';
import { MultiAuthenticatedRequestReducer } from 'components/MultiAuthenticatedRequest/reducers';
import tasks from 'containers/Tasks/reducer';
import { patientDiaryReducer } from 'containers/Ediary/reducer';
import { AdminReducer } from 'containers/AdminPage/reducer';
import userAdminReducer from 'containers/AdminPage/UsersAdmin/reducer';
import { taskAdminReducer } from 'containers/AdminPage/TaskAdmin/reducer';
import { taskReminderAdminReducer } from 'containers/AdminPage/TaskReminderAdmin/reducer';
import { locationAdminReducer } from 'containers/AdminPage/LocationsAdmin/reducer';
import { notificationAdminReducer } from 'containers/AdminPage/NotificationsAdmin/reducer';
import consentAdminReducer from 'containers/AdminPage/ConsentAdmin/reducer';
import { resourceAdminReducer } from 'containers/AdminPage/TeamResource/reducer';
import { adminAuditTrailReducer } from 'containers/AdminPage/AuditTrailAdmin/reducer';
import {
  auditTrailsReducer,
  consentsAuditTrailReducer,
} from 'containers/AuditTrail/reducer';
import paymentsReducer from 'containers/PaymentsPage/reducer';
import siteClosureRequestReducer from 'containers/ViewSiteClosureRequest/reducer';

export default function createReducer(injectedReducers = {}) {
  const rootReducer = combineReducers({
    toasters: toasts,
    currentPatientInfo: patientInfo,
    currentUser: authReducer,
    profile: userInfoReducer,
    prov: getProcedureProvidersReducer,
    loc: getProcedureLocationsReducer,
    addProcedureRequest: addNewProcedureReducer,
    search: patientSearchReducer,
    customFilter: saveSearchFiltersReducer,
    patientCard: patientCardReducer,
    shareOutcomesReport: shareOutcomesReportReducer,
    procedureMasterProvider: getProcedureMasterProvidersReducer,
    counterSigneReducer: getCounterSigneReducer,
    navBarSelectedPerformerReducer,
    patientInfo: patientInfoReducer,
    patientDetailsReducer,
    getDepartmentOnlyProvidersReducer,
    deleteCustomFiltersReducer,
    addNewPatientReducer,
    addPatientReducer: AddPatientReducer,
    getDepartmentProceduresReducer,
    getDepartmentProvidersReducer,
    resendInviteReducer,
    notificationsReducer,
    procedureManagers: procedureManagersReducer,
    consentReducer,
    notMovingForwardReducer,
    updatePatientInfoReducer,
    sendVerificationCodeReducer,
    verifyOTPReducer,
    ModalCloseOnPromiseReducer,
    exitPatientFromStudyReducer,
    downloadSignedConsentReducer,
    consentResendInviteReducer,
    needsAttentionReducer,
    patientNotesReducer,
    documentsReducer,
    setPhaseReducer,
    appointments,
    surveys,
    conversations,
    messages,
    communicationLog,
    patientNoteDetailsReducer,
    MultiAuthenticatedRequestReducer,
    LoadingReducer,
    requestsAndCallBackReducer,
    tasks,
    patientDiaryReducer,
    AdminReducer,
    userAdminReducer,
    locationAdminReducer,
    taskAdminReducer,
    consentAdminReducer,
    taskReminderAdminReducer,
    notificationAdminReducer,
    auditTrailsReducer,
    consentsAuditTrailReducer,
    resourceAdminReducer,
    paymentsReducer,
    cohortsReducer,
    adminAuditTrailReducer,
    siteClosureRequestReducer,
    ...injectedReducers,
  });

  // Wrap the root reducer and return a new root reducer with router state
  return rootReducer;
}

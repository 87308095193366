import DashboardNavigationBar from 'components/DashboardNavigationBar';
import React from 'react';
import './index.scss';
import { clearErrorsForField } from 'common/validator';
import HelpBlock from 'components/HelpBlock';
import {
  TertiaryButton,
  PrimaryButton,
} from 'components/StyleGuideComponents/common/buttons';
import DataTable from 'components/StyleGuideComponents/DataTable';
import Pagination from 'components/Pagination';
import Emptyview from 'components/Emptyview';
import usePaymentsViewController from './ViewController';
import PaymentFilters from './components/PaymentFilters';

const PaymentsPage: React.FC = () => {
  const {
    formData,
    setFormData,
    errors,
    onSubmit,
    paymentHistoryHeaders,
    paymentHistories,
    pagination,
    lastRefreshedAt,
    setPage,
    clearSearchText,
    exportCsv,
    showFilters,
    setShowFilters,
    clearAllFilters,
    filtersCount,
  } = usePaymentsViewController();

  return (
    <div className='payments-page-container'>
      <DashboardNavigationBar activeTab='payments' />
      <div className='payments-page-data-container'>
        <div className='payments-page-data-header'>
          <div className='payment-page-header-container'>
            <form
              autoComplete='off'
              className={`${
                errors.searchText !== undefined ? 'has-error' : ''
              }`}
            >
              <div className='search-form'>
                <input
                  type='text'
                  name='search'
                  value={formData.searchText}
                  onChange={e => {
                    clearErrorsForField(errors, 'searchText');
                    setFormData({ ...formData, searchText: e.target.value });
                  }}
                  placeholder='Find subjects by ID or site name'
                />
                {formData.searchText.length ? (
                  <TertiaryButton
                    onClick={(e: Event) => {
                      e.preventDefault();
                      clearSearchText();
                    }}
                    style={{ width: 'fit-content' }}
                  >
                    <span className='icon icon-font-a-close' />
                  </TertiaryButton>
                ) : (
                  ''
                )}

                <TertiaryButton
                  onClick={(e: SubmitEvent) => {
                    e.preventDefault();
                    onSubmit();
                  }}
                  type='submit'
                  style={{ width: 'fit-content' }}
                >
                  <span className='icon icon-font-a-search-question' />
                </TertiaryButton>
              </div>
              <HelpBlock value={errors.searchText} />
            </form>
            {lastRefreshedAt ? (
              <span className='refresh-text'>
                Last Refreshed: {lastRefreshedAt}
              </span>
            ) : (
              ''
            )}
          </div>
          <div className='payment-page-header-container'>
            <TertiaryButton
              style={{
                display: filtersCount > 0 ? 'block' : 'none',
                width: 'fit-content',
              }}
              onClick={clearAllFilters}
            >
              Clear all
            </TertiaryButton>
            <PrimaryButton
              style={{ width: '85px' }}
              onClick={() => setShowFilters(true)}
            >
              Filter {filtersCount ? `(${filtersCount})` : ''}
            </PrimaryButton>
            <TertiaryButton
              style={{ width: 'fit-content' }}
              onClick={exportCsv}
              disabled={!paymentHistories.length}
            >
              <span className='icon icon-download' />
              &nbsp; EXPORT CSV
            </TertiaryButton>
          </div>
        </div>
        <div className='payments-page-data-content'>
          {paymentHistories.length ? (
            <DataTable
              tableHeaders={paymentHistoryHeaders}
              tableData={paymentHistories}
              style={{ margin: '0px' }}
              tableHeaderCellStyle={{
                textAlign: 'left',
                justifyContent: 'start',
              }}
              tableDataCellStyle={{ textAlign: 'left' }}
            />
          ) : (
            <Emptyview
              className='icon icon-featured_play_list'
              message='No payment histories'
            />
          )}
        </div>
        <div className='payments-page-data-footer'>
          <Pagination
            pagination={pagination}
            title='Payments'
            handlePagination={(toPage: number) => {
              setPage(toPage);
            }}
          />
        </div>
      </div>
      {showFilters && (
        <PaymentFilters
          show={showFilters}
          modalClose={() => setShowFilters(false)}
        />
      )}
    </div>
  );
};

export default PaymentsPage;
